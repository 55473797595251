import styled from 'styled-components'
import toast from '../../images/toast.webp'
import dangered from '../../images/dangered.webp'

const About = () => {
  return (
    <Container>
      <Content>
        <Header>
          Built by:
        </Header>
        <Card href='https://twitter.com/isotach_' target='_blank'>
          <CardImage src={toast} />
          <CardText>
            <CardName>
              iso
            </CardName>
            <CardRole>
              Frontend Developer
            </CardRole>
          </CardText>
        </Card>
        <Card href='https://twitter.com/dangeredwolf' target='_blank'>
          <CardImage src={dangered} />
          <CardText>
            <CardName>
              dangered wolf
            </CardName>
            <CardRole>
              Backend Developer
            </CardRole>
          </CardText>
        </Card>
        <br />
        <Header>
          Based on:
        </Header>
        <Card href='https://ipv4.games' target='_blank'>
          <CardImage src='https://ipv4.games/favicon.ico' />
          <CardText>
            <CardName>
              ipv4.games
            </CardName>
          </CardText>
        </Card>
        <br />
        <Header>
          Our stack:
        </Header>
        <Card href='https://react.dev/' target='_blank'>
          <CardImage
            src='https://reactjs.org/logo-180x180.png'
            square
          />
          <CardText>
            <CardName>
              React
            </CardName>
            <CardRole>
              Frontend Framework
            </CardRole>
          </CardText>
        </Card>
        <Card href='https://expressjs.com' target='_blank'>
          <CardImage
            src='https://avatars.githubusercontent.com/u/5658226?s=200&v=4'
            square
          />
          <CardText>
            <CardName>
              Express
            </CardName>
            <CardRole>
              Backend Framework
            </CardRole>
          </CardText>
        </Card>
        <Card href='https://www.postgresql.org/' target='_blank'>
          <CardImage
            src='https://www.postgresql.org/media/img/about/press/elephant.png'
            square
          />
          <CardText>
            <CardName>
              PostgreSQL
            </CardName>
            <CardRole>
              Database
            </CardRole>
          </CardText>
        </Card>
      </Content>
    </Container>
  )
}

const CardRole = styled.span`
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
`

const CardName = styled.span`
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
`

const CardText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 10px;
`

const CardImage = styled.img<{ square?: boolean }>`
  width: 48px;
  height: 48px;
  border-radius: ${({ square }) => square ? '8px' : '50%'};
`

const Card = styled.a`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0 !important;
  padding: 5px 10px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-decoration: none;
  color: inherit;
`

const Header = styled.h1`
  font-size: 24px;
  text-align: left;
  margin: -5px 0 5px -10px;

  &:first-child {
    margin-top: 0;
  }
`

const Content = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 12px 30px 20px;
  background-color: rgba(200, 200, 200, 0.1);
  border: 1px solid rgba(200, 200, 200, 0.5);
  border-radius: 8px;
  user-select: none;

  div {
    margin: 2px 0;
    font-size: 16px;

    a {
      text-decoration: none;
      color: rgba(0, 0, 255, 0.8);

      &:hover {
        color: rgba(0, 0, 255, 0.5);
      }
    }
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default About
