import styled from "styled-components"
import getBlockColor from "../../lib/colors"
import { useBlocks } from "../../store/selectors"

const Blocks = () => {
  const blocks = useBlocks()

  return (
    <Container>
      <BlockScroller>
        {blocks.map((b, i) => (
          <Block key={i} color={b.owner && getBlockColor(b.owner)}>
            <span>{b.block}.0.0.0/8</span>
            <span>{b.assignee}</span>
            {b.owner && b.owner_address_count && (
              <BlockUserInfo>
                <div>{b.owner}</div>
                <div>{b.owner_address_count} address{b.owner_address_count > 1 ? 'es' : ''}</div>
              </BlockUserInfo>
            )}
          </Block>
        ))}
      </BlockScroller>
    </Container>
  )
}

const BlockUserInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 20px;
`

const Block = styled.div<{ color?: string }>`
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  color: ${(props) => props.color ? 'black' : 'rgba(0, 0, 0, 0.5)'};
  background-color: ${(props) => props.color ? props.color : 'rgba(0,0,0,0.1)'};
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  user-select: none;

  span:first-child {
    font-size: 16px;
    font-weight: bold;
  }

  span {
    font-size: 12px;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.5);
  }
`

const BlockScroller = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(164px, 1fr));
  gap: 10px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
`

export default Blocks
