import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { navbarHeight } from '../lib/constants'
import Navbar from './Navbar'

const Layout = () => {
  return (
    <Container>
      <Navbar />
      <Section>
        <Outlet />
      </Section>
    </Container> 
  )
}

const Section = styled.div`
  box-sizing: border-box;
  width: 100%;
  flex: 1;
  padding: 20px;
  overflow: hidden;
  overflow-y: auto;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`

export default Layout
