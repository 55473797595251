const api = {
  url: 'https://api.ipv4.party',
  endpoints: {
    all: 'all',
    claim: 'claim',
  },
  async fetch(endpoint: string, options?: { query: { [key: string]: string } }) {
    return fetch(`${this.url}/${endpoint}${options?.query?.name ? `?name=${options.query?.name}` : ''}`)
  }
}

export default api
