import useStore from "./slice";

export const useDataLoaded =
  () => useStore(
    (state) => state.hasDataLoaded
  )

export const useIp =
  () => useStore(
    (state) => state.ip
  )

export const useBlocks =
  () => useStore(
    (state) => state.blocks
  )

export const useLeaderboard =
  () => useStore(
    (state) => state.leaderboard
  )
