import create from 'zustand'
import { devtools } from 'zustand/middleware'
import api from '../lib/api'

type Block = {
  block: number
  assignee: string
  owner?: string
  owner_address_count?: number
}

type Player = {
  name: string
  blocks: Block[]
}

type StoreState = {
  hasDataLoaded: boolean
  ip: string
  blocks: Block[]
  leaderboard: Player[]
  fetchData: () => Promise<void>
}

const useStore = create(devtools<StoreState>(
  set => ({
    hasDataLoaded: false,
    ip: '',
    blocks: [],
    leaderboard: [],

    fetchData: async () => {
      try {
        const response = await api.fetch(api.endpoints.all)
        const data: { ip: string, blocks: Block[] } = await response.json()

        if (data.ip && data.blocks) {
          const leaderboard = data.blocks.reduce((acc: Player[], block) => {
            if (block.owner) {
              const player = acc.find(p => p.name === block.owner)
              if (player) {
                player.blocks.push(block)
              } else {
                acc.push({
                  name: block.owner,
                  blocks: [block]
                })
              }
            }
            return acc
          }, [])
            .sort((a, b) => b.blocks.length - a.blocks.length)

          set(state => ({
            ...state,
            hasDataLoaded: true,
            ip: data.ip,
            blocks: data.blocks,
            leaderboard
          }))
        }
      } catch (e) {
        throw e
      }
    }
  })
))

export default useStore
export type { StoreState }
