import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Spinner } from '../../components/Loader'
import api from '../../lib/api'
import { useFetchData } from '../../store/actions'
import { useIp } from '../../store/selectors'

const Claim = () => {
  const navigate = useNavigate()
  const fetchData = useFetchData()
  const ip = useIp()
  const [claim, setClaim] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')

  const handleSubmit = () => {
    setSubmitting(true)
    api.fetch(api.endpoints.claim, {
      query: {
        name: claim,
      }
    }).then(() => {
      setSubmitting(false)
      setSuccess('Claimed successfully!')
      setClaim('')
    }).catch(() => {
      setSubmitting(false)
      setError('Failed to claim!')
    }).finally(() => {
      fetchData()
      setTimeout(() => {
        navigate('/')
      }, 600)
    })
  }

  return (
    <Container>
      <Content>
        <ContentHeader>
          Claim the land at: <IPAddress>{ip}</IPAddress>
        </ContentHeader>
        {!(submitting || success || error) && (
          <InputLabel>Claim this land for:</InputLabel>
        )}
        <ContentBody>
          {submitting || success || error ? (
            <ResponseContainer className={success ? 'success' : error ? 'error' : ''}>
              <ResponseIcon>
                {success ? '✅' : error ? '⚠️' : <ResponseSpinner />}
              </ResponseIcon>
              <ResponseMessage>
                {success ? success : error ? error : 'Claiming...'}
              </ResponseMessage>
            </ResponseContainer>
          ) : (
            <>
              <Input
                type='text'
                placeholder='Enter your name, email, URL, etc.'
                value={claim}
                onChange={(e) => setClaim(e.target.value)}
              />
              <Button onClick={handleSubmit}>
                Claim!
              </Button>
            </>
          )}
        </ContentBody>
      </Content>
    </Container>
  )
}

const ResponseMessage = styled.div`
  flex: 1;
  height: 100%;
  padding: 0 10px;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;
  user-select: none;
`

const ResponseIcon = styled(ResponseMessage)`
  flex: 0;
  font-size: 1.5rem;
  font-weight: bold;
  border-right: 1px solid rgba(200, 150, 0, 0.5);

  .success & {
    border-color: rgba(0, 200, 0, 0.5);
  }

  .error & {
    border-color: rgba(200, 0, 0, 0.5);
  }
`

const ResponseSpinner = styled(Spinner)`
  border-width: 4px;
  border-color: rgba(55, 0, 0, 0.2);
  border-top-color: rgba(55, 0, 0, 0.5);
`

const ResponseContainer = styled.div`
  width: fit-content;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 0, 0.2);
  border: 1px solid rgba(200, 150, 0, 0.5);
  border-radius: 4px;
  color: rgba(55, 30, 10, 0.5);
  overflow: hidden;

  &.success {
    background-color: rgba(0, 255, 0, 0.2);
    border-color: rgba(0, 200, 0, 0.5);
    color: rgba(0, 100, 30, 0.5);
  }

  &.error {
    background-color: rgba(255, 0, 0, 0.2);
    border-color: rgba(200, 0, 0, 0.5);
    color: rgba(150, 0, 30, 0.5);
  }
`

const Button = styled.button`
  appearance: none;
  display: inline-block;
  width: fit-content;
  height: 40px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
`

const Input = styled.input`
  appearance: none;
  display: inline-block;
  flex-grow: 1;
  height: 40px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-right: none;
  border-radius: 4px 0 0 4px;
  padding: 0 10px;

  &:placeholder {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 300;
  }
`

const InputLabel = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 300;
  margin-right: auto;
  margin-bottom: 5px;
`

const ContentBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const IPAddress = styled.div`
  display: inline-block;
  font-weight: normal;
  user-select: all;
`

const ContentHeader = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  user-select: none;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: rgba(200, 200, 200, 0.1);
  border: 1px solid rgba(200, 200, 200, 0.5);
  border-radius: 8px;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default Claim
