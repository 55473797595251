import styled, { keyframes } from 'styled-components'

const Loader = (props: { error?: string }) => {
  return (
    <Container>
      {props.error ? (
        <Text
          style={{
            color: 'rgba(255, 0, 0, 0.5)',
            fontSize: '4rem',
          }}
        >{'⚠️'}</Text>
      ) : (
        <Spinner />
      )}
      <VerticalSeparator size={props.error ? 72 : 48} />
      <TextContainer>
        <Text>{props.error ? props.error : 'Loading...'}</Text>
        {props.error && (
          <Text>
            {'Try '}
            <TextButton onClick={
              () => window.location.reload()
            }>
              {'reloading'}
            </TextButton>
            {' the page?'}
          </Text>
        )}
      </TextContainer>
    </Container>
  )
}

export default Loader

const VerticalSeparator = styled.div<{ size: number }>`
  width: 0;
  height: ${(props) => props.size}px;
  margin: 0 1.2rem;
  border-left: 4px solid rgba(0, 0, 0, 0.35);
`

const Text = styled.span`
  color: rgba(0, 0, 0, 0.5);
  font-size: 32px;
  line-height: 32px;
  font-weight: bold;
  user-select: none;
`

const TextButton = styled(Text)`
  cursor: pointer;
  color: rgba(0, 0, 255, 0.5);
  text-decoration: underline;

  &:hover {
    color: rgba(0, 0, 255, 0.7);
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
`

const spinnerAnimation = keyframes`
  from {
    transform: rotate(45deg);
  }
  to {
    transform: rotate(405deg);
  }
`

export const Spinner = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 6px solid rgba(0, 0, 0, 0.2);
  border-top-color: rgba(0, 0, 0, 0.5);
  transform: rotate(45deg);
  animation: ${spinnerAnimation} 1s infinite linear;
`

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`
