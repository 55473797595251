import { useState, useEffect } from 'react'
import { 
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom'
import './App.css';
import ErrorBoundary from './components/ErrorBoundary';
import Loader from './components/Loader';
import About from './features/about/About';
import Blocks from './features/blocks/Blocks';
import Claim from './features/home/Claim';
import Layout from './features/Layout';
import Leaderboard from './features/leaderboard/Leaderboard';
import { useFetchData } from './store/actions';
import { useDataLoaded } from './store/selectors';

const FrameRoutes = () => {
  const [error, setError] = useState<string | undefined>('');
  const hasDataLoaded = useDataLoaded()
  const fetchData = useFetchData()

  useEffect(() => {
    if (!hasDataLoaded) {
      fetchData().catch((e) => setError('Failed to load data!'));
    }
  }, [fetchData, hasDataLoaded])

  return (
    <Routes>
      {!hasDataLoaded ? (
        <Route path="*" element={<Loader error={error} />} />
      ) : (
        <Route element={<Layout />}>
          <Route path="/" element={<Blocks />} />
          <Route path="/claim" element={<Claim />} />
          <Route path="/blocks" element={<Blocks />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<Navigate to={'/'} />} />
        </Route>
      )}
    </Routes>
  )
}

const Frame = () => {
  return (
    <Router>
      <FrameRoutes />
    </Router>
  )
}

const App = () => {
  return (
    <ErrorBoundary>
      <Frame />
    </ErrorBoundary>
  )
}

export default App
