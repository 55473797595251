import { Component, PropsWithChildren } from 'react'
import Loader from './Loader';

export default class ErrorBoundary extends Component<PropsWithChildren, any> {
    

  constructor(props: PropsWithChildren) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    ;(this as any).setState({ hasError: true });
    return { hasError: true };
  }

  render() {
    return this.state.hasError ? (
      <Loader error={'Something went wrong!'} />
    ) : (
      <>
        {this.props.children}
      </>
    )
  }
}
