import { useState } from 'react'
import styled from 'styled-components'
import { useLeaderboard } from '../../store/selectors'

const Leaderboard = () => {
  const leaderboard = useLeaderboard()
  const [more, setMore] = useState(false)

  return (
    <Container>
      <Board>
        <BoardHeader>
          🥇 Leaderboard
        </BoardHeader>
        {leaderboard.filter((_, i) => i < 10 || more).map((player, index) =>
          index < 3 ? (
            <BoardHoistedItem key={index} rank={index}>
              <span>{`${index + 1}.`}&nbsp;&nbsp;&nbsp;{player.name}</span>
              <span>{`${player.blocks.length} block${player.blocks.length > 1 ? 's' : ''}`}</span>
            </BoardHoistedItem>
          ) : (
            <BoardItem key={index}>
              <span>{`${index+1}.`}&nbsp;&nbsp;&nbsp;{player.name}</span>
              <span>{`${player.blocks.length} block${player.blocks.length > 1 ? 's' : ''}`}</span>
            </BoardItem>
          ))}
          {leaderboard.length === 0 ? (
            <BoardSpecialItem>
              Oops, it looks like nobody has claimed a block yet!
            </BoardSpecialItem>
          ) : !more && leaderboard.length > 10 ? (
            <BoardSpecialItem clickable onClick={() => setMore(true)}>
              Show all
            </BoardSpecialItem>
          ) : more && (
            <BoardSpecialItem clickable onClick={() => setMore(false)}>
              Show less
            </BoardSpecialItem>
          )}
      </Board>
    </Container>
  )
}

const BoardItem = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  border-bottom: 1px solid rgba(200, 200, 200, 0.5);

  &:last-child {
    border-bottom-width: 0;
  }
`

const BoardHoistedItem = styled(BoardItem)<{ rank: number }>`
  transform: scale(${({ rank }) => [1.1, 1.06, 1.03][rank]});
  margin-bottom: ${({ rank }) => [2.1, 0.7, 0][rank]}px;
  font-weight: bold;
  background-color: #${({ rank }) => ['F4EACA', 'EEEEEE', 'F1DDC9'][rank]};
  border: 1px solid #${({ rank }) => ['E4CC7F', 'D7D7D7', 'DFAD7C'][rank]};
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  z-index: ${({ rank }) => 4 - rank};

  &:last-child {
    border-bottom-width: 1px;
  }
`

const BoardSpecialItem = styled(BoardItem)<{ clickable?: boolean }>`
  justify-content: center;
  font-weight: bold;
  color: ${({ clickable }) => clickable ? 'rgba(0, 0, 255, 0.8)' : 'inherit'};
  cursor: ${({ clickable }) => clickable ? 'pointer' : 'inherit'};
  user-select: none;

  &:hover {
    color: ${({ clickable }) => clickable ? 'rgba(0, 0, 255, 0.5)' : 'inherit'};
  }
`

const BoardHeader = styled.div`
  width: 100%;
  font-size: 32px;
  font-weight: bold;
  text-align: left;
  padding: 20px;
  margin-bottom: 5px;
  user-select: none;
`

const Board = styled.div`
  box-sizing: border-box;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
  background-color: rgba(200, 200, 200, 0.1);
  border: 1px solid rgba(200, 200, 200, 0.5);
  border-radius: 8px;
`

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default Leaderboard
