import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { navbarHeight } from '../lib/constants'
import { useIp } from '../store/selectors'

const Navbar = () => {
  const ip = useIp()

  return (
    <Container>
      <NavLink label='IPv4 Turf War' to='/' />
      <VerticalSeparator />
      <NavLink label='Leaderboard' to='/leaderboard' />
      <VerticalSeparator />
      <NavLink label='Claim IP' to='/claim' />
      <VerticalSeparator />
      <NavLink label='About' to='/about' />
      <IPAddress>Your IP: {ip}</IPAddress>
    </Container>
  )
}

export default Navbar

const VerticalSeparator = styled.div`
  width: 0;
  height: 24px;
  border-left: 1px solid rgba(0, 0, 0, 0.5);
`

const IPAddress = styled.div`
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.5);
  margin-left: auto;
`

const NavLink = (props: { label: string, to: string }) => {
  const active = useLocation().pathname === props.to

  return (
    <NavLinkContainer active={active}>
      <NLink
        to={props.to}
        active={active}
      >
        {props.label}
      </NLink>
    </NavLinkContainer>
  )
}

const NLink = styled(Link)<{ active?: boolean }>`
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
  color: rgba(0, 0, 0, ${(props) => props.active ? 0.7 : 0.5});
  user-select: none;
`

const NavLinkContainer = styled.div<{ active?: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${navbarHeight}px;
  padding: 0;
  margin: 0;
  padding-top: ${(props) => props.active ? '3px' : '0'};
  border-bottom: ${(props) => props.active ? '3px' : 0} solid rgb(76, 76, 76);
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
  height: ${navbarHeight}px;
  padding: 0 20px;
  background-color: #fafafa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`